import { User } from '@neynar/nodejs-sdk/build/neynar-api/v1'
import { ErrorRes } from '@neynar/nodejs-sdk/build/neynar-api/v2'
import axios, { AxiosError } from 'axios'
import useLocalStorage from 'hooks/useLocalStorageState'
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { verifyUser } from 'utils/helpers'
import { toast } from 'react-toastify'
import { UserInfo } from 'types/UserInfo'

type SetState<T> = React.Dispatch<React.SetStateAction<T>>

interface Props {
  children: ReactNode
}

const getAddr = async (contractAddr: string): Promise<string[]> => {
  const apiKey = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY
  const baseUrl = `https://base-mainnet.g.alchemy.com/nft/v3/${apiKey}/getOwnersForContract?`
  const url = `${baseUrl}contractAddress=${contractAddr}&withTokenBalances=false`

  try {
    const result = await fetch(url, {
      headers: { accept: 'application/json' },
    })
    const data = await result.json()
    return data.owners
  } catch (error) {
    console.error('Error fetching data:', error)
    return []
  }
}

const getUserByAddress = async (address: string): Promise<any> => {
  const apiKey = `${process.env.NEXT_PUBLIC_NEYNAR_API_KEY}`
  const baseUrl = 'https://api.neynar.com/v2/farcaster/user/bulk-by-address'
  const url = `${baseUrl}?addresses=${address}&viewer_fid=3`

  try {
    const result = await fetch(url, {
      headers: {
        accept: 'application/json',
        api_key: apiKey,
      },
    })
    const data = await result.json()
    return data
  } catch (error) {
    console.error('Error fetching user data:', error)
    return null
  }
}

interface NeynarContextInterface {
  userData: User | null
  setUserData: SetState<User | null>
  signerUuid: string | null
  setSignerUuid: SetState<string | null>
  fid: string | null
  setFid: SetState<string | null>
  getAddr: (contractAddr: string) => Promise<string[]>
  getUserByAddress: (address: string) => Promise<any>
}

const NeynarContext = createContext<NeynarContextInterface | null>(null)

export const NeynarProvider: FC<Props> = ({ children }) => {
  const [signerUuid, setSignerUuid] = useState<string | null>(null)
  const [userData, setUserData] = useState<User | null>(null)
  const [fid, setFid] = useState<string | null>(null)
  const [user, setUser, removeUser] = useLocalStorage<UserInfo | null>(
    'user',
    null
  )

  const lookupUser = useCallback(async () => {
    if (user && user.fid) {
      try {
        const { data } = await axios.get<{ user: User }>(
          `/api/user/${user.fid}`
        )
        setUserData(data.user)
        setFid(user.fid)
      } catch (err) {
        const axiosError = err as AxiosError<ErrorRes>
        toast(axiosError.response?.data.message || 'An error occurred', {
          type: 'error',
          theme: 'dark',
          autoClose: 3000,
          position: 'bottom-right',
          pauseOnHover: true,
        })
      }
    }
  }, [user])

  useEffect(() => {
    lookupUser()
  }, [lookupUser])

  const isUserLoggedIn = useCallback(async () => {
    if (signerUuid && fid) {
      const verifiedUser = await verifyUser(signerUuid, fid)
      if (verifiedUser) {
        setUser({ signerUuid, fid })
      } else {
        removeUser()
      }
    }
  }, [signerUuid, fid])

  useEffect(() => {
    isUserLoggedIn()
  }, [isUserLoggedIn])

  const value: NeynarContextInterface | null = useMemo(
    () => ({
      userData,
      setUserData,
      signerUuid,
      setSignerUuid,
      fid,
      setFid,
      getAddr,
      getUserByAddress
    }),
    [userData, setUserData, signerUuid, fid]
  )

  return (
    <NeynarContext.Provider value={value}>{children}</NeynarContext.Provider>
  )
}

export const useApp = (): NeynarContextInterface => {
  const context = useContext(NeynarContext)
  if (!context) {
    throw new Error('NeynarContext must be used within AppProvider')
  }
  return context
}
