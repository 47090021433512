import React, { useContext } from 'react'
import { styled } from '@stitches/react'
import { AudioContext } from 'context/AudioContextProvider'
import { useMediaQuery } from 'react-responsive'
import MobileMusicPlayer from './MobileMusicPlayer'
import DesktopMusicPlayer from './DesktopMusicPlayer'

const PlayerBubble = styled('div', {
  flex: 1,
  padding: '10px',
  position: 'fixed',
  right: 20,
  bottom: 70,
  borderRadius: '20px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  zIndex: 9,
  background: '$blue',
  color: '$white',
})

const MusicPlayer: React.FC = () => {
  const { currentListen, isPlayerMobileOpen, setIsPlayerMobileOpen } =
    useContext(AudioContext)

  const isMobile = useMediaQuery({ query: '(max-width: 960px' })

  return (
    Object.keys(currentListen).length > 0 && (
      <>
        {!isMobile ? (
          <DesktopMusicPlayer />
        ) : (
          <>
            {isPlayerMobileOpen && (
              <MobileMusicPlayer />
            )}
          </>
        )}
      </>
    )
  )
}
export default MusicPlayer
