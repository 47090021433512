import { faFireAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Flex, Text } from 'components/primitives'
import { Dialog } from 'components/primitives/Dialog'
import React, { useState, useContext, useEffect } from 'react'
import Image from 'next/image'
import { styled } from '@stitches/react'
import { AudioContext } from 'context/AudioContextProvider'
import { UserInfo } from 'types/UserInfo'
import useLocalStorage from 'hooks/useLocalStorageState'
import { toast } from 'react-toastify'

const Form = styled('form', {
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  padding: '1rem',
  borderRadius: '8px',
  backgroundColor: '#1a1a1a',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
})

const TextArea = styled('textarea', {
  width: '100%',
  height: '100px',
  padding: '0.5rem',
  fontSize: '1rem',
  borderRadius: '4px',
  borderColor: '#333',
  backgroundColor: '#222',
  color: '#fff',
})

const ButtonStyled = styled(Button, {
  margin: '0 0.5rem',
})

const FlexCentered = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'center',
})

const CastModal = () => {
  const [open, setOpen] = useState(false)
  const [user, _1, removeItem] = useLocalStorage<UserInfo>('user')
  const [message, setMessage] = useState('')
  const { currentListen } = useContext(AudioContext)

  useEffect(() => {
    if (currentListen) {
      const { title } = currentListen
      const messageText = `I'd like to share the song I'm currently listening to on Platinum: ${title}. Listen here: ${
        window.location.origin + window.location.pathname
      }`
      setMessage(messageText)
    }
  }, [currentListen])

  const handleToggle = () => setOpen(!open)
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault()

    if (!user.signerUuid) {
      return
    }

    const req = await fetch('/api/casts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        signerUid: user.signerUuid,
        text: message,
      }),
    })

    if (req.ok) {
      toast('Cast created successfully!', {
        type: 'success',
        autoClose: 5000,
        position: 'bottom-right',
      })

      setMessage('')
    } else {
      toast('Error creating cast', {
        type: 'error',
        autoClose: 5000,
        position: 'bottom-right',
      })
    }

    setOpen(false)
  }

  return (
    <>
      <FontAwesomeIcon
        icon={faFireAlt}
        onClick={handleToggle}
        style={{ cursor: 'pointer' }}
      />
      <Dialog onOpenChange={handleToggle} open={open}>
        <Form onSubmit={handleSubmit}>
          <Flex align="center" justify="center">
            <Image
              src="/farcaster-logo.png"
              alt="Farcaster Logo"
              width={24}
              height={24}
            />
            <Text css={{ marginLeft: 10 }}>Create a Cast</Text>
          </Flex>
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write your cast..."
          />
          <Flex justify="center">
            <ButtonStyled type="submit">Send</ButtonStyled>
            <ButtonStyled type="button" onClick={handleToggle}>
              Cancel
            </ButtonStyled>
          </Flex>
        </Form>
      </Dialog>
    </>
  )
}

export default CastModal
