import { toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'
import { ErrorRes } from '@neynar/nodejs-sdk/build/neynar-api/v2'

export const verifyUser = async (signerUuid: string, fid: string) => {
  let _isVerifiedUser = false
  try {
    const {
      data: { isVerifiedUser },
    } = await axios.post('/api/verify-user', { signerUuid, fid })
    _isVerifiedUser = isVerifiedUser
  } catch (err) {
    const { message } = (err as AxiosError).response?.data as ErrorRes
    if (message) {
      toast(message, {
        type: 'error',
        theme: 'dark',
        autoClose: 3000,
        position: 'bottom-right',
        pauseOnHover: true,
      })
    }
  }
  return _isVerifiedUser
}
