import { useState, useEffect } from 'react'

export const resolveIpfsUrl = (ipfsUrl: string): string => {
  if (ipfsUrl.startsWith('ipfs://')) {
    return ipfsUrl.replace('ipfs://', 'https://ipfs.io/ipfs/')
  }
  return ipfsUrl
}

const formatDuration = (duration: number): string => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  if (hours > 0) {
    return `${hours}h:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else if (minutes > 0) {
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return `${seconds}`;
  }
};

export const useAudio = (audioUrl: string) => {
  const [duration, setDuration] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const resolvedUrl = resolveIpfsUrl(audioUrl)
    const audio = new Audio(resolvedUrl)

    const handleLoadedMetadata = () => {
      setDuration(formatDuration(audio.duration))
      setIsLoading(false)
    }

    const handleError = (error: any) => {
      setError('Error fetching audio duration')
      setIsLoading(false)
      console.error('Error fetching audio duration:', error)
    }

    audio.addEventListener('loadedmetadata', handleLoadedMetadata)
    audio.addEventListener('error', handleError)

    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata)
      audio.removeEventListener('error', handleError)
    }
  }, [audioUrl])

  return { duration, isLoading, error }
}
