import { resolveIpfsUrl } from 'hooks/useAudio'
import Hls from 'hls.js'
import React, {
  createContext,
  FC,
  useState,
  ReactNode,
  useRef,
  useEffect,
  MutableRefObject,
} from 'react'
import { Collection, DynamicTokens } from '@reservoir0x/reservoir-kit-ui'

export interface SongInfo {
  id: string | null
  mediaUrl: string | null
  coverUrl: string | null
  artist: string | null
  title: string | null
  album: string | null
}

interface Price {
  amount: number | null
  currency: string | null
  decimals: number | null
  contract: string | null
}

interface FloorAsk {
  id: string | null
  maker: string | null
  price: Price | null
  source: string | null
  validFrom: string | null
  validUntil: string | null
}

interface Metadata {
  imageOriginal: string | null
  mediaOriginal: string | null
  imageMimeType: string | null
  mediaMimeType: string | null
  tokenURI: string | null
}

interface CollectionInfo {
  id: string
  name: string
  image: string
  slug: string
  symbol: string
}

export interface PlaylistCollection {
  floorAsk: FloorAsk | null
  token: {
    chainId: number
    collection: CollectionInfo
    contract: string
    description: string | null
    image: string
    imageLarge: string
    imageSmall: string
    isFlagged: boolean
    isNsfw: boolean
    isSpam: boolean
    kind: string
    lastFlagChange: string | null
    lastFlagUpdate: string | null
    media: string
    metadata: Metadata
    metadataDisabled: boolean
    mintStages: any[]
    name: string
    owner: string
    rarity: number
    rarityRank: number
    remainingSupply: string
    supply: string
    tokenId: string
    updatedAt: string
  }
}

interface Listener {
  socketId: string;
  username: string;
  displayName: string;
  pfp: string;
}

interface AudioContextProps {
  audio: any[]
  audioRef: MutableRefObject<HTMLAudioElement | null> | null
  setAudio: React.Dispatch<React.SetStateAction<any[]>>
  songPosition: number
  volume: number
  previousVolume: number
  setSongPosition: React.Dispatch<React.SetStateAction<number>>
  songDuration: number
  setSongDuration: React.Dispatch<React.SetStateAction<number>>
  currentListen: SongInfo
  setCurrentListen: React.Dispatch<React.SetStateAction<any>>
  isPlaying: boolean
  isStreaming: boolean
  setIsStreaming: React.Dispatch<React.SetStateAction<boolean>>
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
  currentIndex: number
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>
  songFinish: boolean
  setSongFinish: React.Dispatch<React.SetStateAction<boolean>>
  totalSongs: number
  setTotalSongs: React.Dispatch<React.SetStateAction<number>>
  playSong: (mediaUrl: string) => void
  pauseSong: () => void
  resumeSong: () => void
  handleVolumeSlider: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  toggleMute: () => void
  handleScrub: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  toggleAudio: (collection?: DynamicTokens[0]) => void
  createPlaylist: (collections: PlaylistCollection[]) => void
  playNextSong: () => void
  playPreviousSong: () => void
  toggleShuffle: () => void
  toggleRepeat: () => void
  listeners: Listener[]
  setListeners: React.Dispatch<React.SetStateAction<Listener[]>>
  playStreaming: (address: string, collection: Collection) => void
  shuffleMode: boolean
  repeatMode: boolean
  isPlayerChatOpen: boolean
  setIsPlayerChatOpen: React.Dispatch<React.SetStateAction<boolean>>
  isPlayerMobileOpen: boolean
  setIsPlayerMobileOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultAudioContext: AudioContextProps = {
  audio: [],
  audioRef: null,
  volume: 0.5,
  previousVolume: 0.5,
  setAudio: () => {},
  songPosition: 0,
  setSongPosition: () => {},
  songDuration: 0,
  setSongDuration: () => {},
  currentListen: {
    id: null,
    album: null,
    artist: null,
    coverUrl: null,
    mediaUrl: null,
    title: null,
  },
  setCurrentListen: () => {},
  isPlaying: false,
  setIsPlaying: () => {},
  isStreaming: false,
  setIsStreaming: () => {},
  currentIndex: 0,
  setCurrentIndex: () => {},
  songFinish: false,
  setSongFinish: () => {},
  totalSongs: 0,
  setTotalSongs: () => {},
  playSong: () => {},
  pauseSong: () => {},
  resumeSong: () => {},
  handleVolumeSlider: () => {},
  toggleMute: () => {},
  handleScrub: () => {},
  toggleAudio: () => {},
  createPlaylist: () => {},
  playNextSong: () => {},
  playPreviousSong: () => {},
  toggleShuffle: () => {},
  toggleRepeat: () => {},
  playStreaming: () => {},
  listeners: [],
  setListeners: () => {},
  shuffleMode: false,
  repeatMode: false,
  isPlayerChatOpen: false,
  setIsPlayerChatOpen: () => {},
  isPlayerMobileOpen: false,
  setIsPlayerMobileOpen: () => {},
}

export const AudioContext =
  createContext<AudioContextProps>(defaultAudioContext)

interface AudioContextProviderProps {
  children: ReactNode
}

const AudioContextProvider: FC<AudioContextProviderProps> = ({ children }) => {
  const [volume, setVolume] = useState(0.5)
  const [previousVolume, setPreviousVolume] = useState(volume)
  const [listeners, setListeners] = useState<Listener[]>([])
  const [audio, setAudio] = useState<any[]>([])
  const [isStreaming, setIsStreaming] = useState(false)
  const [isPlayerChatOpen, setIsPlayerChatOpen] = useState<boolean>(false)
  const [isPlayerMobileOpen, setIsPlayerMobileOpen] = useState<boolean>(false)
  const [streamingData, setStreamingData] = useState<
    | {
        address: string
        collection: Collection
      }
    | undefined
  >()
  const [songPosition, setSongPosition] = useState<number>(0)
  const [songDuration, setSongDuration] = useState<number>(0)
  const [currentListen, setCurrentListen] = useState<any>({})
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [songFinish, setSongFinish] = useState<boolean>(false)
  const [totalSongs, setTotalSongs] = useState<number>(0)

  const [shuffleMode, setShuffleMode] = useState(false)
  const [repeatMode, setRepeatMode] = useState(false)

  const audioRef = useRef<HTMLAudioElement | null>(null)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', () => {
        setSongPosition(audioRef.current?.currentTime || 0)
      })
      audioRef.current.addEventListener('durationchange', () => {
        setSongDuration(audioRef.current?.duration || 0)
      })
      audioRef.current.addEventListener('ended', handleSongEnd)
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('timeupdate', () => {
          setSongPosition(audioRef.current?.currentTime || 0)
        })
        audioRef.current.removeEventListener('durationchange', () => {
          setSongDuration(audioRef.current?.duration || 0)
        })
        audioRef.current.removeEventListener('ended', handleSongEnd)
      }
    }
  }, [audio, currentIndex, shuffleMode, repeatMode])

  const handleSongEnd = () => {
    if (repeatMode) {
      playSong(audio[currentIndex].mediaUrl!)
    } else if (shuffleMode) {
      playRandomSong()
    } else {
      playNextSong()
    }
  }

  useEffect(() => {
    if (audioRef?.current) {
      audioRef.current.volume = volume
    }
    if (videoRef.current && isStreaming) {
      videoRef.current.volume = volume
    }
  }, [volume])

  const handleVolumeSlider = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const newVolume = e.nativeEvent.offsetX / e.currentTarget.offsetWidth
    setVolume(newVolume)
    setPreviousVolume(newVolume)
  }

  const handleScrub = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (audioRef?.current) {
      const scrubTime =
        (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) * songDuration
      audioRef.current.currentTime = scrubTime
    }
  }

  const toggleMute = () => {
    if (volume !== 0) {
      setPreviousVolume(volume)
      setVolume(0)
    } else {
      setVolume(previousVolume)
    }
  }

  const playSong = async (mediaUrl: string) => {
    try {
      const resolvedUrl = resolveIpfsUrl(mediaUrl)
      const response = await fetch(resolvedUrl)
      const blob = await response.blob()
      const objectURL = URL.createObjectURL(blob)

      if (audioRef.current) {
        audioRef.current.src = objectURL
        const playPromise = audioRef.current.play()
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true)
              setSongFinish(false)
            })
            .catch((error) => {
              console.error('Error playing audio:', error)
              setIsPlaying(false)
            })
        }
      }
    } catch (error) {
      console.error('Error fetching and playing audio:', error)
    }
  }

  const createPlaylist = (collections: PlaylistCollection[]) => {
    if (!audio.length) {
      const formattedCollections = collections.map((collection) => ({
        id: collection.token.name,
        mediaUrl: collection.token.metadata.mediaOriginal,
        coverUrl: collection.token.image,
        artist: collection.token.collection.name,
        title: collection.token.name,
        album: collection.token.kind || 'Unknown Album',
      }))

      if (formattedCollections.length > 0) {
        setAudio(formattedCollections)
        setTotalSongs(formattedCollections.length)

        const firstSong = formattedCollections[0]
        playSong(`${firstSong.mediaUrl}`)
        setCurrentListen(firstSong)
        setCurrentIndex(0)
      }
    }
  }

  const playStreaming = (
    address: string | undefined,
    collection: Collection | undefined
  ) => {
    try {
      if (videoRef.current && address && collection) {
        let newListen = {
          id: collection.id,
          coverUrl: collection.image,
          mediaUrl: null,
          artist: null,
          title: collection.name,
          album: collection.description,
        }
        if (isPlaying && isStreaming) {
          videoRef.current?.pause()
        } else {
          if (Hls.isSupported()) {
            const hls = new Hls()
            hls.loadSource(address)
            hls.attachMedia(videoRef.current)
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
              setCurrentListen(newListen)
              videoRef.current?.play()
            })
          } else if (
            videoRef.current.canPlayType('application/vnd.apple.mpegurl')
          ) {
            videoRef.current.src = address
            videoRef.current.addEventListener('loadedmetadata', () => {
              setCurrentListen(newListen)
              videoRef.current?.play()
            })
          }
        }

        videoRef.current.addEventListener('play', () => {
          if (videoRef?.current) {
            setStreamingData({
              address: address,
              collection,
            })
            setIsStreaming(true)
            setIsPlaying(true)
          }
        })

        videoRef.current.addEventListener('pause', () => {
          if (videoRef.current) {
            setIsPlaying(false)
          }
        })
      }
    } catch (error) {
      console.error('Error fetching and playing audio:', error)
    }
  }

  const playNextSong = () => {
    if (audio.length > 0) {
      if (shuffleMode) {
        playRandomSong()
      } else {
        let nextIndex = currentIndex + 1
        if (nextIndex >= audio.length) {
          nextIndex = 0 // Loop to the beginning if at the end
        }
        const nextSong = audio[nextIndex]

        playSong(nextSong.mediaUrl)
        setCurrentListen(nextSong)
        setCurrentIndex(nextIndex)
      }
    }
  }

  const playPreviousSong = () => {
    if (audio.length > 0) {
      let prevIndex = currentIndex - 1
      if (prevIndex < 0) {
        prevIndex = audio.length - 1 // Loop to the end if at the beginning
      }
      const prevSong = audio[prevIndex]
      playSong(prevSong.mediaUrl)
      setCurrentListen(prevSong)
      setCurrentIndex(prevIndex)
    }
  }

  const playRandomSong = () => {
    if (audio.length > 0) {
      let randomIndex = Math.floor(Math.random() * audio.length)
      while (randomIndex === currentIndex) {
        randomIndex = Math.floor(Math.random() * audio.length)
      }
      const randomSong = audio[randomIndex]
      playSong(randomSong.mediaUrl)
      setCurrentListen(randomSong)
      setCurrentIndex(randomIndex)
    }
  }

  const toggleShuffle = () => {
    setShuffleMode(!shuffleMode)
  }

  const toggleRepeat = () => {
    setRepeatMode(!repeatMode)
  }

  const pauseSong = () => {
    if (audioRef.current) {
      audioRef.current.pause()
      setIsPlaying(false)
    }
  }

  const resumeSong = () => {
    if (audioRef.current && !isPlaying) {
      audioRef.current.play()
      setIsPlaying(true)
    }
  }

  const toggleAudio = (collection?: DynamicTokens[0]) => {

    const handlePlayIfCollectionExists = () => {
      if (currentListen.id === collection?.token?.name) {
        if (isPlaying) {
          pauseSong()
        } else {
          resumeSong()
        }
      } else {
        playSong(`${collection?.token?.metadata?.mediaOriginal}`)
        setCurrentListen({
          id: collection?.token?.name,
          mediaUrl: collection?.token?.metadata?.mediaOriginal,
          coverUrl: collection?.token?.image,
          artist: 'Unknown Artist',
          title: collection?.token?.name,
          album: collection?.token?.kind || 'Unknown Album',
        })
      }
    }


    if (!isStreaming) {
      if (!collection) {
        if (isPlaying) {
          pauseSong()
        } else {
          resumeSong()
        }
        setIsPlaying(!isPlaying)
      } else {
        handlePlayIfCollectionExists();
      }
    } else {
      if (!collection) {
        playStreaming(streamingData?.address, streamingData?.collection)
      } else {
        videoRef?.current?.pause()
        setIsStreaming(false)
        handlePlayIfCollectionExists()
      }
    }
  }

  return (
    <AudioContext.Provider
      value={{
        audio,
        audioRef,
        volume,
        previousVolume,
        playNextSong,
        playPreviousSong,
        createPlaylist,
        toggleAudio,
        handleScrub,
        toggleMute,
        handleVolumeSlider,
        setAudio,
        songPosition,
        setSongPosition,
        songDuration,
        setSongDuration,
        currentListen,
        setCurrentListen,
        isPlaying,
        setIsPlaying,
        currentIndex,
        setCurrentIndex,
        songFinish,
        setSongFinish,
        totalSongs,
        setTotalSongs,
        playSong,
        pauseSong,
        resumeSong,
        toggleShuffle,
        toggleRepeat,
        shuffleMode,
        repeatMode,
        playStreaming,
        isStreaming,
        setIsStreaming,
        listeners,
        setListeners,
        isPlayerChatOpen,
        setIsPlayerChatOpen,
        isPlayerMobileOpen,
        setIsPlayerMobileOpen
      }}
    >
      {children}
      <video ref={videoRef} style={{ display: 'none' }} />
      <audio ref={audioRef} />
    </AudioContext.Provider>
  )
}

export default AudioContextProvider
