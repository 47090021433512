import { useContext, useEffect, useRef, useState } from 'react'
import { AnimatedOverlay, Content } from 'components/primitives/Dialog'
import { Button, Flex } from 'components/primitives'
import { Avatar } from 'components/primitives/Avatar'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { AudioContext } from 'context/AudioContextProvider'
import { BsChatLeftDotsFill } from 'react-icons/bs'
import { useWebsocket } from 'context/WebsocketContextProvider'
import { createStitches } from '@stitches/react'
import { formatDistanceToNow } from 'date-fns'
import { useApp } from 'context/NeynarContextProvider'

const { styled } = createStitches({
  theme: {
    colors: {
      black: '#000000',
      blue: '#5353CE',
      white: '#FFFFFF',
      gray: '#EBEBEF',
    },
  },
})

const Container = styled('div', {
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
})

const Header = styled('div', {
  padding: '10px',
  color: '$white',
  backgroundColor: '$blue',
  textAlign: 'center',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
})

const ChatArea = styled('div', {
  flex: 1,
  padding: '10px',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

const MessageContainer = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: '10px',

  variants: {
    fromUser: {
      true: {
        flexDirection: 'row-reverse',
      },
      false: {
        flexDirection: 'row',
      },
    },
  },
})

const MessageContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

const Message = styled('div', {
  backgroundColor: '$gray',
  color: '$black',
  padding: '10px',
  borderRadius: '5px',
  maxWidth: '200px',
  height: 'auto',
  wordWrap: 'break-word',
  variants: {
    fromUser: {
      true: {
        backgroundColor: '$blue',
        color: '$white',
        alignSelf: 'flex-end',
      },
    },
  },
})

const MessageInfo = styled('div', {
  fontSize: '12px',
  color: '$white',
  marginTop: '5px',

  variants: {
    fromUser: {
      true: {
        color: '$white',
        textAlign: 'right',
      },
    },
  },
})

const MessageAvatar = styled('img', {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  marginRight: '10px',
  backgroundSize: 'cover',

  variants: {
    fromUser: {
      true: {
        marginRight: '0',
        marginLeft: '10px',
      },
    },
  },
})

const InputArea = styled('div', {
  display: 'flex',
  padding: '10px',
  borderTop: '1px solid $gray',
})

const Input = styled('input', {
  flex: 1,
  padding: '10px',
  borderRadius: '5px',
  border: 'none',
})

const ChatBubble = styled('div', {
  flex: 1,
  padding: '10px',
  position: 'fixed',
  right: 20,
  bottom: 20,
  borderRadius: '20px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  zIndex: 9,
  background: '$blue',
  color: '$white',
})

const Chat = () => {
  const { setIsPlayerChatOpen, isPlayerChatOpen } = useContext(AudioContext)
  const { messages, sendMessage, loadMoreMessages, socket } = useWebsocket()!
  const { userData } = useApp()
  const [newMessage, setNewMessage] = useState('')
  const chatAreaRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(true)

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      sendMessage(newMessage, userData?.pfp.url)
      setNewMessage('')
    }
  }

  useEffect(() => {
    const chatArea = chatAreaRef.current
    if (chatArea) {
      chatArea.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (chatArea) {
        chatArea.removeEventListener('scroll', handleScroll)
      }
    }
  }, [messages])

  const handleScroll = () => {
    const chatArea = chatAreaRef.current
    if (chatArea && chatArea.scrollTop === 0) {
      loadMoreMessages(messages.length)
    }
  }

  if(!userData) return null

  return isPlayerChatOpen ? (
    <DialogPrimitive.Root
      onOpenChange={setIsPlayerChatOpen}
      open={isPlayerChatOpen}
    >
      <AnimatePresence>
        {open && (
          <DialogPrimitive.DialogPortal forceMount>
            <AnimatedOverlay
              css={{ backgroundColor: '$sidebarOverlay' }}
              style={{ opacity: 0.6 }}
            />
            <Content
              forceMount
              asChild
              css={{
                right: 0,
                top: 0,
                bottom: 0,
                transform: 'none',
                left: 'unset',
                width: 395,
                maxWidth: 395,
                minWidth: 395,
                maxHeight: '100vh',
                background: '$gray1',
                border: 0,
                borderRadius: 0,
              }}
            >
              <motion.div
                transition={{ type: 'tween', duration: 0.4 }}
                initial={{
                  opacity: 0,
                  right: '-100%',
                }}
                animate={{
                  opacity: 1,
                  right: 0,
                }}
                exit={{
                  opacity: 0,
                  right: '-100%',
                }}
              >
                <Flex justify="between" align="center">
                  <Container>
                    <Header>
                      <Avatar src="/logo.svg" alt="Channel Avatar" />
                      <span>Platinium Chat</span>

                      <Button
                        color="ghost"
                        css={{ color: '$gray10', ml: 'auto', mr: 10 }}
                        onClick={() => {
                          // setOpen(false)
                          setIsPlayerChatOpen(false)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faClose}
                          height={18}
                          width={18}
                        />
                      </Button>
                    </Header>
                    <ChatArea>
                      {messages.map((msg, index) => (
                        <MessageContainer
                          key={index}
                          fromUser={Boolean(msg.fromUser === socket?.id || msg.avatar === userData?.pfp.url)}
                        >
                          <MessageAvatar
                            src={msg.avatar || '/default-image.png'}
                            alt={`${
                              Boolean(msg.fromUser === socket?.id || msg.avatar === userData?.pfp.url)
                                ? 'User'
                                : 'Sender'
                            } Avatar`}
                            fromUser={Boolean(msg.fromUser === socket?.id || msg.avatar === userData?.pfp.url)}
                          />
                          <MessageContent>
                            <Message
                              fromUser={Boolean(msg.fromUser === socket?.id || msg.avatar === userData?.pfp.url)}
                            >
                              {msg.msg}
                            </Message>
                            <MessageInfo>
                              {`sent ${formatDistanceToNow(
                                Number(msg.timestamp)
                              )} ago`}
                            </MessageInfo>
                          </MessageContent>
                        </MessageContainer>
                      ))}
                    </ChatArea>
                    <InputArea>
                      <Input
                        placeholder="Type a message..."
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') handleSendMessage()
                        }}
                        value={newMessage}
                      />
                      <Button
                        onClick={handleSendMessage}
                        css={{ marginLeft: 5 }}
                      >
                        Send
                      </Button>
                    </InputArea>
                  </Container>
                </Flex>
              </motion.div>
            </Content>
          </DialogPrimitive.DialogPortal>
        )}
      </AnimatePresence>
    </DialogPrimitive.Root>
  ) : (
    <ChatBubble>
      <BsChatLeftDotsFill
        size={20}
        style={{ cursor: 'pointer' }}
        onClick={() => setIsPlayerChatOpen(true)}
      />
    </ChatBubble>
  )
}

export default Chat
