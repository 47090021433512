import React, {
  createContext,
  useCallback,
  useRef,
  useEffect,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react'
import io, { Socket } from 'socket.io-client'
import { useApp } from './NeynarContextProvider'

type WebsocketStore = {
  subscriptions: Record<number, Record<string, number>>
  socket: Socket | null
  messages: Message[]
  sendMessage: (msg: string, avatar: string | undefined) => void
  loadMoreMessages: (start: number) => void
}

type Message = {
  timestamp: string
  msg: string
  fromUser: string
  avatar: string | undefined 
}

function websocketStore() {
  const store = useRef<WebsocketStore>({
    subscriptions: {},
    socket: null,
    messages: [],
    sendMessage: () => {},
    loadMoreMessages: () => {},
  })

  const [messages, setMessages] = useState<Message[]>([])
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)

  const get = useCallback(() => store.current, [])

  const subscribe = useCallback(
    (
      chainId: number,
      messages: any[], // Assuming ReservoirWebsocketMessage is any for this example
      onSubscribe: (message: any) => void,
      onUnsubscribe: (message: any) => void
    ) => {
      messages.forEach((message) => {
        let subscription = message.event as string
        if (message.filters) {
          Object.keys(message.filters)
            .sort()
            .forEach((key) => {
              subscription = `${subscription}-${key}:${message.filters?.[key]}`
            })
        }
        if (message.changed) {
          subscription += `:${message.changed}`
        }
        if (!store.current.subscriptions[chainId]) {
          store.current.subscriptions[chainId] = {}
        }

        if (!store.current.subscriptions[chainId][subscription]) {
          store.current.subscriptions[chainId][subscription] = 0
        }

        const channelSubscriptions =
          store.current.subscriptions[chainId][subscription]

        if (message.type === 'unsubscribe') {
          store.current.subscriptions[chainId][subscription] -= 1
          if (channelSubscriptions <= 0) {
            store.current.subscriptions[chainId][subscription] = 0
            onUnsubscribe(message)
          }
        } else {
          if (!channelSubscriptions) {
            store.current.subscriptions[chainId][subscription] = 1
            onSubscribe(message)
          } else {
            store.current.subscriptions[chainId][subscription] += 1
          }
        }
      })
    },
    []
  )

  useEffect(() => {
    let socket: any = null
    const apiUrl = process.env.NEXT_PUBLIC_API_URL;
    try {
      socket = io(`${apiUrl}`, { transports: ['websocket'] })
    } catch (err) {
      console.log(err);
    }

    socket.on('chat message', (msg: Message) => {
      setMessages((prevMessages) => [...prevMessages, msg])
    })

    socket.on('more messages', (newMessages: Message[]) => {
      setMessages((prevMessages) => [...newMessages, ...prevMessages])
      setLoading(false)
      if (newMessages.length < 10) setHasMore(false)
    })

    socket.on('last messages', (newMessages: Message[]) => {
      setMessages(newMessages)
      setLoading(false)
    })

    store.current.socket = socket

    store.current.sendMessage = (msg: string, avatar: string| undefined) => {
      const userId = socket.id
      const message: Message = {
        timestamp: Date.now().toString(),
        msg,
        fromUser: userId || '',
        avatar
      }
      socket.emit('chat message', message)
    }

    store.current.loadMoreMessages = (start: number) => {
      if (hasMore) {
        setLoading(true)
        socket.emit('load more messages', start)
      }
    }

    return () => {
      socket.disconnect()
    }
  }, [hasMore])

  return {
    get,
    subscribe,
    socket: store.current.socket,
    messages,
    sendMessage: store.current.sendMessage,
    loadMoreMessages: store.current.loadMoreMessages,
  }
}

export const WebsocketContext = createContext<ReturnType<
  typeof websocketStore
> | null>(null)

export const WebsocketContextProvider: FC<PropsWithChildren> = function ({
  children,
}) {
  const websocketContextValue = websocketStore()
  return (
    <WebsocketContext.Provider value={websocketContextValue}>
      {children}
    </WebsocketContext.Provider>
  )
}

export const useWebsocket = () => useContext(WebsocketContext)