import React, { useContext } from 'react'
import { styled } from '@stitches/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlay,
  faPause,
  faStepBackward,
  faStepForward,
} from '@fortawesome/free-solid-svg-icons'
import { AudioContext } from 'context/AudioContextProvider'
import { useTheme } from 'next-themes'
import CastModal from './CastModal'
import { useApp } from 'context/NeynarContextProvider'

const MobileContainer = styled('div', {
  height: '100vh',
  width: '100vw',
  background: '$$bg-color',
  color: '$$color',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 10,
  padding: '20px',
})

const AlbumCover = styled('img', {
  width: '30%',
  borderRadius: '10px',
  marginBottom: '20px',
})

const TrackInfo = styled('div', {
  textAlign: 'center',
  marginBottom: '20px',
})

const TrackTitle = styled('p', {
  margin: 0,
  fontSize: '1.2rem',
  fontWeight: 'bold',
})

const ArtistName = styled('p', {
  margin: 0,
  fontSize: '1rem',
  color: '#555',
})

const Buffer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '80%',
  marginBottom: '20px',
})

const Time = styled('span', {
  fontSize: '0.8rem',
  color: '#555',
})

const BufferSlider = styled('div', {
  flex: 1,
  height: '4px',
  background: '#ddd',
  borderRadius: '2px',
  display: 'flex',
  alignItems: 'center',
  marginLeft: '10px',
  marginRight: '10px',
  position: 'relative',
  cursor: 'pointer',
})

const BufferProgress = styled('div', {
  height: '7px',
  background: '#004D40',
  borderRadius: '100px',
  flexBasis: '0%',
  position: 'relative',
  transition: 'flex-basis 200ms',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-6px',
    right: '-10px',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: '2px solid #004D40',
    background: '#E0F2F1',
  },
})

const Controls = styled('div', {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '80%',
})

const Icon = styled(FontAwesomeIcon, {
  fontSize: '1.5rem',
  cursor: 'pointer',
})

const PlayPauseIcon = styled(FontAwesomeIcon, {
  fontSize: '2rem',
  cursor: 'pointer',
})

const MobileMusicPlayer = () => {
  const {
    currentListen,
    isPlaying,
    handleScrub,
    songPosition,
    songDuration,
    toggleAudio,
  } = useContext(AudioContext)

  const { userData } = useApp()

  const { theme } = useTheme()

  const bgColor = theme === 'light' ? '#FFFFFF' : '#18181A'
  const color = theme === 'light' ? '#73767D' : '#FFFFFF'

  return (
    <MobileContainer
      css={{
        '$$bg-color': bgColor,
        $$color: color,
      }}
    >
      <AlbumCover src={`${currentListen.coverUrl}`} alt="Album cover" />
      <TrackInfo>
        <TrackTitle>{currentListen.title}</TrackTitle>
        <ArtistName>{currentListen.artist}</ArtistName>
        {userData?.displayName && <CastModal />}
      </TrackInfo>
      <Buffer>
        <Time className="time-left">02:41</Time>
        <BufferSlider onClick={handleScrub}>
          <BufferProgress
            style={{ flexBasis: `${(songPosition / songDuration) * 100}%` }}
          ></BufferProgress>
        </BufferSlider>
        <Time className="time-duration">03:20</Time>
      </Buffer>
      <Controls>
        <Icon icon={faStepBackward} />
        <PlayPauseIcon
          icon={isPlaying ? faPause : faPlay}
          onClick={() => toggleAudio()}
        />
        <Icon icon={faStepForward} />
      </Controls>
    </MobileContainer>
  )
}

export default MobileMusicPlayer
