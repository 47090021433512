import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FaCircle } from 'react-icons/fa'
import { Box, Card, Flex, Text } from 'components/primitives'
import * as Popover from '@radix-ui/react-popover'
import { Avatar } from 'components/primitives/Avatar'
import Link from 'next/link'
import {
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeDown,
  faVolumeMute,
  faUsers,
  faFireAlt,
} from '@fortawesome/free-solid-svg-icons'
import { styled } from '@stitches/react'
import { useTheme } from 'next-themes'
import { useContext } from 'react'
import { AudioContext } from 'context/AudioContextProvider'
import { useApp } from 'context/NeynarContextProvider'
import useLocalStorage from 'hooks/useLocalStorageState'
import { UserInfo } from 'types/UserInfo'
import CastModal from './CastModal'

const Container = styled('div', {
  height: '80px',
  overflow: 'hidden',
  background: '$$bg-color',
  color: '$$color',
  borderTop: '2px solid $$border-color',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '15px',
  zIndex: 9,
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    height: '200px',
  },
})

const MusicPlayerTop = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '20%',
  '@media (max-width: 600px)': {
    width: '100%',
    marginBottom: '10px',
  },
})

const MusicPlayerContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '77%',
  '@media (max-width: 600px)': {
    width: '100%',
  },
})

const AlbumCover = styled('img', {
  width: '50px',
  height: '50px',
  borderRadius: '8px',
  marginRight: '15px',
  '@media (max-width: 600px)': {
    width: '50px',
    height: '50px',
    marginRight: '10px',
  },
})

const ArtistInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
  '@media (max-width: 600px)': {
    width: '100%',
    alignItems: 'center',
  },
})

const ArtistName = styled('p', {
  margin: 0,
  fontSize: '1rem',
  fontWeight: 'bold',
  '@media (max-width: 600px)': {
    fontSize: '0.9rem',
  },
})

const SongTitle = styled('span', {
  fontSize: '0.9rem',
  '@media (max-width: 600px)': {
    fontSize: '0.8rem',
  },
})

const AlbumName = styled('p', {
  margin: 0,
  fontSize: '0.8rem',
  color: '#CCC',
  '@media (max-width: 600px)': {
    fontSize: '0.7rem',
  },
})

const Buffer = styled('div', {
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
})

const Time = styled('span', {
  fontSize: '1.2rem',
})

const BufferSlider = styled('div', {
  width: '80%',
  height: '7px',
  background: '$$border-color',
  borderRadius: '100px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
})

const BufferProgress = styled('div', {
  height: '7px',
  background: '#004D40',
  borderRadius: '100px',
  flexBasis: '0%',
  position: 'relative',
  transition: 'flex-basis 200ms',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-6px',
    right: '-10px',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: '2px solid #004D40',
    background: '#E0F2F1',
  },
})

const MusicPlayerBottom = styled('div', {
  width: '100%',
})

const Controls = styled('div', {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '100%',
})

const ControlsLeft = styled('div', {
  width: '15%',
  display: 'flex',
  justifyContent: 'space-evenly',
})

const ControlsMiddle = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
})

const ControlsRight = styled('div', {
  width: '25%',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
})

const VolumeSlider = styled('div', {
  width: '60%',
  height: '7px',
  background: 'black',
  borderRadius: '100px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
})

const VolumeProgress = styled('div', {
  width: '50%',
  height: '7px',
  background: '#004D40',
  borderRadius: '100px',
  flexBasis: '50%',
  position: 'relative',
  transition: 'flex-basis 200ms',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-6px',
    right: '-10px',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    border: '2px solid #004D40',
    background: '#E0F2F1',
  },
})

const LiveContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'red',
  borderRadius: 2,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 4,
  paddingBottom: 4,
})

const Icon = styled(FontAwesomeIcon, {
  fontSize: '1rem',
  cursor: 'pointer',
})

const PlayPauseIcon = styled(FontAwesomeIcon, {
  fontSize: '1.5rem',
  cursor: 'pointer',
})

const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60)
  const seconds = Math.floor(time % 60)
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

const DesktopMusicPlayer = () => {
  const { theme } = useTheme()
  const [user] = useLocalStorage<UserInfo>("user");
  // console.log(user);
  const {
    currentListen,
    songPosition,
    songDuration,
    isPlaying,
    handleVolumeSlider,
    volume,
    handleScrub,
    toggleMute,
    toggleAudio,
    isStreaming,
    listeners,
  } = useContext(AudioContext)

  const { userData } = useApp()

  const bgColor = theme === 'light' ? '#FFFFFF' : '#18181A'
  const borderColor = theme === 'light' ? '#F2F2F4' : '#1F2023'
  const color = theme === 'light' ? '#73767D' : '#FFFFFF'

  return (
    <Container
      css={{
        '$$bg-color': bgColor,
        '$$border-color': borderColor,
        $$color: color,
      }}
    >
      <MusicPlayerTop>
        <AlbumCover src={`${currentListen.coverUrl}`} alt="Album cover" />
        <ArtistInfo>
          <ArtistName>
            {currentListen.artist} <SongTitle>{currentListen.title}</SongTitle>
          </ArtistName>
          <AlbumName>{currentListen.album}</AlbumName>
        </ArtistInfo>
      </MusicPlayerTop>
      <MusicPlayerContainer>
        <Buffer>
          {!isStreaming ? (
            <Time className="time-left">{formatTime(songPosition)}</Time>
          ) : (
            <LiveContainer>
              {' '}
              <FaCircle
                style={{
                  color: 'white',
                  width: 10,
                  height: 10,
                  marginRight: 5,
                }}
              />{' '}
              <Text>Live</Text>
            </LiveContainer>
          )}

          <BufferSlider onClick={handleScrub}>
            <BufferProgress
              style={{ flexBasis: `${(songPosition / songDuration) * 100}%` }}
            ></BufferProgress>
          </BufferSlider>
          <Time className="time-duration">
            {isStreaming ? '0:00' : formatTime(songDuration)}
          </Time>
        </Buffer>

        <MusicPlayerBottom>
          <Controls>
            <ControlsLeft>
              {listeners.length > 0 && (
                <Popover.Root>
                  <Popover.Trigger>
                    <Text>
                      {listeners.length} <Icon icon={faUsers} />{' '}
                    </Text>
                  </Popover.Trigger>
                  <Popover.Content>
                    <Box>
                      <Card
                        css={{
                          padding: 10,
                          overflowY: 'auto',
                          maxHeight: 200,
                        }}
                      >
                        {listeners.map((listener) => (
                          <Link
                            href={`https://warpcast.com/${listener.username}`}
                            target="_blank"
                            key={listener.username}
                          >
                            <Flex css={{ alignItems: 'center' }}>
                              <Avatar
                                width={20}
                                height={20}
                                src={listener.pfp}
                              />
                              <Box css={{ marginLeft: 10 }}>
                                <Text>{listener.displayName}</Text>
                              </Box>
                            </Flex>
                          </Link>
                        ))}
                      </Card>
                    </Box>
                  </Popover.Content>
                </Popover.Root>
              )}

              {/* <Icon
            icon={!repeatMode ? faRedo : faRepeat}
            onClick={() => toggleRepeat()}
          /> */}
              {/* <Icon
            color={!shuffleMode ? '#333' : '#FFF'}
            icon={faRandom}
            onClick={() => toggleShuffle()}
          /> */}
              {/* <Icon icon={faFireAlt} /> */}
              {userData?.displayName && <CastModal />}
            </ControlsLeft>
            <ControlsMiddle>
              {/* <Icon icon={faStepBackward} onClick={() => playPreviousSong()} /> */}
              <PlayPauseIcon
                icon={isPlaying ? faPause : faPlay}
                onClick={() => toggleAudio()}
              />
              {/* <Icon icon={faStepForward} onClick={() => playNextSong()} /> */}
            </ControlsMiddle>
            <ControlsRight>
              <VolumeSlider onClick={handleVolumeSlider}>
                <VolumeProgress
                  style={{ flexBasis: `${volume * 100}%` }}
                ></VolumeProgress>
              </VolumeSlider>
              <Icon
                icon={
                  volume > 0.5
                    ? faVolumeUp
                    : volume > 0
                    ? faVolumeDown
                    : faVolumeMute
                }
                onClick={toggleMute}
              />
            </ControlsRight>
          </Controls>
        </MusicPlayerBottom>
      </MusicPlayerContainer>
    </Container>
  )
}

export default DesktopMusicPlayer
